.container {
  display: flex;
}

.desktopContainer {
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
}

.mobileContainer {
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.block{
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.mobileBlock {
  grid-gap: 4px;
  gap: 4px;
}

.list {
  display: flex;
}

.desktopList {
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.mobileList {
  flex-wrap: wrap;
  grid-gap: 4px 8px;
  gap: 4px 8px;
}

.item {
  white-space: pre;
}

.text {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.mobileText {
  font-size: 12px;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.mobileTitle {
  font-size: 16px;
}
