.container {
  width: 100%;
  position: relative;

  margin: 0 auto;
  max-width: 1200px;
  min-width: 798px;
}

.backgroundContainer {
  width: 100%;
  position: relative;

  margin: 0 auto;
  max-width: 1060px;
  min-width: 798px;
}

.contentContainer {
  max-width: 1220px;
  margin: 0 auto;
}

.headContainer {
  position: relative;
  width: 100%;
  height: 510px;
  background-color: lightblue;
}

.backgroundHeadContainer {
  position: relative;
  width: 100%;
  height: 500px;
}

.headContentContainer {
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.headerShadow {
  padding-top: 10px;
  background: linear-gradient(
    180deg,
    rgba(234, 234, 234, 0.8) 28.65%,
    rgba(242, 242, 242, 0) 100%
  );
  position: relative;
  z-index: 502;
  /**
     * 501 - z-index сиги
     */
}

.headerShadowWhitelabel {
  background: linear-gradient(rgba(0, 0, 0, 0.5) 28.65%, rgba(0, 0, 0, 0) 100%);
}

.flightMap {
  display: block;
  position: relative;

  object-fit: cover;

  height: 100%;
  width: 100%;
}
