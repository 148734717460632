.container {
    font-size: 0;
}

.containerMobile {
    max-width: 300px;
    margin: 5px auto;
    padding-bottom: 50px;
}

.containerDesktop {
    width: 900px;
    margin: 0 auto 50px;
    padding-left: 30px;
}

.countryLink {
    width: 20%;
    display: inline-block;
    font-size: 15px;
    text-decoration: none;
    color: #434343;
    vertical-align: middle;
    margin: 4px 0;
}

.countryLinkMobile {
    width: 130px;
    margin: 10px 0 10px 20px;
}

.flag {
    vertical-align: top;
    margin-right: 7px;
}