.container {
    max-width: 1176px;
}

.switchers {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.pseudoContent {
    display: flex;
    align-items: center;
}

.pseudoContent::after {
    content: ' ';
    width: 65%;
    height: 16px;
    background-color: #ededed;
    border-radius: 3px;
    display: block;
    margin: 0 auto;
}

.switcher {
    flex-basis: 101px;
    height: 38px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-left-width: 0;
    composes: pseudoContent;
}

.departureSwitcher {
    composes: switcher;
    margin-right: 45px;
    flex-basis: 173px;
    border-left-width: 1px;
    border-radius: 4px;
}

.firstSwitcher {
    composes: switcher;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left-width: 1px;
}

.lastSwitcher {
    composes: switcher;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
}

.chartWrapper {
    background-color: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.28);
    height: 356px;
}

.chart {
    position: relative;
    margin: 0 110px;
    height: 330px;
}

.chart::before {
    content: ' ';
    width: 100%;
    height: 1px;
    background-color: #dfdfdf;
    position: absolute;
    top: 100px;
}

.chart::after {
    content: ' ';
    width: 100%;
    height: 1px;
    background-color: #dfdfdf;
    position: absolute;
    top: 200px;
}

.tabs {
    display: flex;
}

.tab {
    flex-basis: 197px;
    height: 46px;
    background-color: #f5f5f5;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    margin-right: -1px;
    composes: pseudoContent;
}

.activeTab {
    composes: tab;
    background-color: inherit;
    border-bottom: 0;
    border-left: 0;
}

.preloader {
    top: 50%;
    left: 50%;
    z-index: 1;
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    margin-top: -10px;
}

.preloader::before {
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: -15px;
    margin-left: -15px;
    position: absolute;
    border-style: solid;
    border-radius: 100%;
    border-color: #444;
    border-width: 2px;
    border-right-color: #efefef;
    border-bottom-color: #efefef;
    animation: spin 1s linear infinite;
}