.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #17181a;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4d4d4d;
}

.container {
  display: flex;
}

.logo {
  margin: 0 8px 0 0;
  border-radius: 8px;
  width: 52px;
  height: 52px;
}

.content {
  min-width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
