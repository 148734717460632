.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerMobile {
    flex-direction: column;
    align-items: flex-start;
}

.heading {
    font-size: 24px;
    line-height: 33px;
    color: black;
    margin: 20px 0 20px;
    text-align: center;
}

.headingHotelsVariant {
    margin: 20px 0 14px;
}

.headingMobile {
    margin-left: 8px;
    margin: 20px 0 8px;
}