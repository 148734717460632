.header {
    font-size: var(--small-text-size);
    width: 100%;
    padding: 0 0 1.3em 1.3em;
    display: flex;
    justify-content: space-between;
    height: 4.45em;
    z-index: 2;
}

.header a {
    /* Чтобы PartnerLogo не провалилось под главную */
    z-index: 2;
}

.header img {
    margin: 0;
}

.burger {
    height: 100%;
    z-index: 2;
    padding: 1.3em 1.3em 0;
}

.logo {
    height: 100%;
    z-index: 2;
    padding: 1.3em 0 0;
}

.logo .logo_svg__text {
    fill: #fff;
}

.link {
    z-index: 2;
    height: 100%;
}

.button {
    z-index: 2;
    height: 100%;
    border: none;
    background: none;
}