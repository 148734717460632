.card {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 8px;
  margin-bottom: 40px;
  border-radius: 4px;
  width: 240px;
  min-height: 0px;
}

.hotelsRelinking {
  height: 100%;
}

.hotelsRelinkingMobile {
  margin-bottom: 20px;
}

.cardHotelsVariant {
  width: 240px;
  min-height: 214px;
  composes: hotelsRelinking;
}

.cardHotelsVariantMobile {
  width: 180px;
  composes: hotelsRelinkingMobile;
}

.newYearCountriesRelinking {
  height: 280px;
}

.newYearCountriesRelinkingMobile {
  height: 310px;
}

.cardCountriesWithPricesVariant {
  width: 268px;
  composes: newYearCountriesRelinking;
}

.cardCountriesWithPricesVariantMobile {
  width: 240px;
  composes: newYearCountriesRelinkingMobile;
}

.cardDefaultCountriesVariant {
  min-height: 290px;
}

.cardDefaultCountriesVariantMobile {
  min-height: 320px;
}

.cardMetaregionsVariant {
  min-height: 210px;
}

.cardMetaregionsVariantMobile {
  min-height: 310px;
}

.shadow {
  box-shadow: rgba(210, 208, 197, 0.9) 0px 1px 2px,
    rgba(216, 214, 204, 0.9) 0px 10px 15px;
}
