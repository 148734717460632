.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    padding: 0 8px;
    overflow-x: auto;
    scrollbar-width: none; /* Hide scrollbar on Firefox */
    -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */

.wrapper::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
}