.customDescription {
  background: none !important;
  color: #fff;
  width: 100%;
  z-index: 15;
  height: 100%;
  position: relative;
  text-align: center;
  text-shadow: 0 0 10px #919499;
  padding: 40px 25px 0;
  margin-bottom: -125px; /* хак, чтобы не менять высоту обертки сиги */
}

.title {
  position: absolute;
  color: white;
  text-align: center;
  padding: 25px 25px 0;
  margin-bottom: -125px; /* хак, чтобы не менять высоту обертки сиги */
}

.customDescription .header_promo_title {
  font-size: 50px;
}

.customDescription .header_promo_text {
  font-size: 24px;
  white-space: pre-line;
}
