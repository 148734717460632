.container {
    font-style: normal;
    font-weight: 400;
    font-size: var(--small-text-size);
    line-height: 1.16em;
    color: var(--light-gray);
    z-index: 2;
    width: 100%;
}

.container > * + * {
    margin-top: 0.416em;
}

.containerDesktop {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.containerDesktopWhitelabel {
    justify-content: space-evenly;
}

.loupe {
    fill: var(--light-gray);
}

.resentlyBuy {
    stroke: var(--light-gray);
}

.seaview {
    stroke: var(--light-gray);
}
