.container {
    color: #4d4d4d;
    font-size: 14px;
    line-height: 16px;
  
    display: flex;
    flex-direction: column;
    align-items: center;
}

.relinkingWrapperMobile {
    margin-top: 20px;
    margin-left: -8px;
    padding: 8px;
}