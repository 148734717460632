.categoryWrapper {
  display: flex;
}

.category {
  display: flex;
  color: var(--black);
}

.wlCategory {
  color: var(--white);
}

.wlCategory svg path {
  fill: var(--white);
}

.category:hover {
  color: var(--orange);
}

.category:hover svg path {
  fill: var(--orange);
}

.fullServices ul {
    right: inherit;
    right: 0;
    left: auto;
}
