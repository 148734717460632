.cardHeadingText {
    margin-top: 0px;
    margin-bottom: 6px;
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin-left: 4px;
}

.cardHeadingTextWithMargin {
    margin-top: 130px;
}

.cardHeadingTextMobile {
    margin-bottom: 14px;
}

.cardHeadingTextWithMarginMobile {
    margin-top: 88px;
}

.cardHeadingTextBlack {
    color: #17181a;
}

.cardHeadingLink {
    display: block;
    text-decoration: none;
}