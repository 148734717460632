.header {
  display: flex;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  min-height: 82px;
  padding: 6px 66px;
  color: #fff;
  align-items: flex-end;
  transform: translateZ(0);
  background-color: var(--lime);
}

.transparentHeader {
  background-color: transparent;
}

.wlHeader {
  background-color: var(--black);
}

.mobileHeader {
  min-height: 74px;
  padding: 4px;
}

.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
}

.isOpenedForm {
  align-items: stretch;
  flex-direction: column;
  grid-gap: 21px;
  gap: 21px;
  padding-top: 19px;
  padding-bottom: 14px;
}

.portalledHeader {
  position: fixed !important;
  top: 0px;
}

.menu {
  margin-left: auto;
  color: #fff !important;
}

.menu :global .messanger_svg__menu-messanger {
  fill: #fff;
}

.menuWithBlackColor {
  color: var(--black) !important;
}

.menuWithBlackColor :global .messanger_svg__menu-messanger {
  fill: #292929;
}

.menuFullHeight {
  height: 38px;
}

.menuCollapsed {
  margin-bottom: 6px;
}

.widgetWrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  width: 100%;
  background-color: var(--lime);
  padding: 8px;
  position: relative;
  z-index: 1004;
}
