.wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    color: rgb(145, 148, 153);
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    width: 25px;
    height: 25px;
    cursor: pointer;
}