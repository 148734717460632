.link {
    display: block;
    height: 100%;
}

.countryContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 10px 10px 10px;
    box-sizing: border-box;
    padding: 8px;
    composes: absoluteDivStyles from "../../../../../styles.module.css";
}

.labelsContainer {
    height: 124px;
    position: relative;
}