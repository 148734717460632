.container {
  margin: 40px 0 20px;
}

.headingContainer {
  color: #737373;
  text-align: center;
}

.headingTitle {
  font-size: 24px;
  line-height: 33px;
  color: black;
}

.headingDescription {
  font-size: 14px;
}

.widgetContainer {
  padding: 20px 10px;
  min-height: 439px;
  position: relative;
}

.widgetElement *,
.widgetElement *::before,
.widgetElement *::after {
  box-sizing: content-box;
}

.widgetElementHidden {
  display: none;
}
