.container {
  font-size: var(--small-text-size);
  width: 100%;
  height: calc(100% - 5em);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.container > * + * {
  margin-top: 1em;
}

.containerMobile {
  padding: 2em 1.3em;
}

.containerDesktop {
  height: 340px;
}

.containerBackgroundDesktop {
  height: 341px;
}

.containerWebview {
  justify-content: center;
}

.searcherWrapperDesktop {
  position: relative;
  background: #17181a;
  border-radius: 6px;
  padding: 10px;
  flex-direction: column;
  justify-content: start;
  pointer-events: all;
  height: 130px;

  /* Перекрываем DesktopAppBanner */
  z-index: 501;
}

.searcherFooterDesktop {
  display: flex;
  margin-top: 8px;
  height: 50px;
}

.searcherFooterMobile {
  margin-top: 12px;
  position: relative;
  z-index: 1;
}

.appBannersWrapper {
  width: 308px;
  display: flex;
  justify-content: space-between;
}
