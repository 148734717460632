.backgroundImage {
  display: block;
  object-fit: cover;
  height: 400px;
  border-radius: 16px;
  width: 1200px;
}

.backgroundImageContainer {
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 100px;
}

.title {
  position: absolute;
  color: white;
  font-size: 36px;
  font-weight: 500;
  padding-top: 87px;
}
