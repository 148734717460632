.sectionView {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  filter: brightness(65%);
}

.topGradient {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  mix-blend-mode: normal;
  opacity: 0.2;
  height: 14vh;
  width: 100%;
  z-index: 1;
}

.bottomGradient {
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  mix-blend-mode: normal;
  opacity: 0.6;
  height: 37vh;
  width: 100%;
  z-index: 1;
}

.tourGuideBannerWrapper {
  padding: 8px 4px 0;
}
