.headerOrder {
    border-radius: 3px;
    border: 1px solid #efefef;
    padding: 3px 10px;
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: auto;
}

.label {
    font-size: 13px;
    color: #efefef;
}

.description {
    font-size: 10px;
    color: #efefef;
}