.searchWidgetPreview {
    /* Посчитано исходя из отступов бургера в MobileMenu*/
    margin-right: 56px;
}

.widgetWrapper {
    padding: 19px 66px 14px;
    background-color: var(--lime);

    display: flex;
    flex-direction: column;
    grid-gap: 21px;
    gap: 21px;
}

.mobileWidgetWrapper {
    padding: 8px;
    grid-gap: 24px;
    gap: 24px;
}

.wlWidgetWrapper {
    background-color: var(--black);
}

.widgetWrapper :global .lt-search-form.expanded .search-form-wrapper {
    position: relative;
}