.link {
    color: #17181a !important;
    display: block;
}

.cardImage {
    margin-bottom: 8px;
    height: 150px;
    border: none;
    border-radius: 4px;
}

.cardText {
    margin-left: 4px;
}

.container {
    color: #4d4d4d;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}