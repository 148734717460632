.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.sidebarWrapper {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: -210px;
    width: 210px;
    height: 100vh;
    transition: all 200ms cubic-bezier(0.25, 0.25, 0.75, 0.75);

}

.openedSidebar {
    transform: translate3d(210px, 0px, 0px)
}

.menuWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;

    height: 100dvh;
    background: var(--white);
    overflow: scroll;
    padding: 8px 16px;
}

.menuLink {
    display: flex;
    grid-gap: 8px;
    gap: 8px;

    padding: 8px 0;
    align-items: center;
}

.menuText {
    color: var(--black);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.wishlistCount {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;
    background-color: var(--background);
    border-radius: 50%;

    color: var(--black);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.bottomLink {
    padding: 8px 0;
}

.mobileStoreContainer {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    flex-direction: column;
}

.sideNavBg {
    background-color: var(--black);
    opacity: var(--disabled-opacity);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}