/* Шрифты загружены на cdn и в ближайшее время меняться не планируют */

/*
  Фоллбек на woff для IE11
  @see https://caniuse.com/#search=woff
*/
@mixin font-face($font-name, $weight: normal, $style: normal) {
  $cdn: 'https://jsapi.cdn.level.travel/fonts';
  $version: '2.004';
  $path: '#{$cdn}/backpack/#{$version}/#{$font-name}';

  @font-face {
    font-family: 'Backpack';

    src: url($path + '.woff2') format('woff2'),
      url($path + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

// usage
@include font-face('Backpack-Regular', normal, normal);
@include font-face('Backpack-Bold', bold, normal);
