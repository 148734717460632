.backgroundImage {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  
    object-fit: cover;
    object-position: center;
}

.imageOverlay {
    background: linear-gradient(
        0deg,
        rgba(23, 24, 26, 0.3),
        rgba(23, 24, 26, 0.3)
    );
}