.cardButtonWrapper {
    color: #007aff;
    background: #fff;
    border: none;
    border-radius: 28px;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    margin: 2px 4px 2px 0;
    cursor: pointer;
    padding: 3px 8px 5px;
}

.cardButtonWrapperMobile {
    padding: 11px 8px 13px;
}

.cardButtonWrapperBlue {
    background: #e6f2ff;
}