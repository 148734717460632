.container {
    display: flex;
    z-index: inherit;
    font-size: var(--small-text-size);
    order: var(--order);
    width: 180px;
}

.container > * + * {
    margin-left: 0.334em;
}

.containerMobile {
    width: 100%;
}

.containerFirstOrderDesktop {
    width: 300px;
}

.iconContainer {
    height: 30px;
    width: 30px;
}

.iconContainerMobile {
    height: 24px;
    width: 24px;
}

.textContainer {
    white-space: pre-line;
}

.skeletonIcon {
    width: 2em;
    height: 2em;
    opacity: 0.5;
}

.skeletonTextFirst {
    width: 70%;
    height: 1em;
    opacity: 0.5;
}

.skeletonTextSecond {
    width: 90%;
    height: 1em;
    opacity: 0.5;
}

.skeletonParagraph {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.skeletonParagraph > * + * {
    margin-top: 0.17em;
}