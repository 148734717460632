.hotelCountry {
    position: absolute;
    top: 8px;
    left: 8px;
  
    padding: 2.5px 8px 1.5px;
  
    border: none;
    border-radius: 16px;
  
    background: #fff;
    color: #4d4d4d;
  
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.hotelRating {
    position: absolute;
    opacity: 0.9;
    top: 4px;
    right: 4px;
}