.regionLabelContainer {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    border-radius: 11px;
    height: 38px;
    margin: 2px;
    padding: 3px 8px 5px 8px;
    box-sizing: border-box;
    background: #fff;
}

.regionPlaceName {
    color: #007aff;
    font-size: 12px;
    line-height: 16px;
}

.regionPrice {
    color: #4d4d4d;
    font-size: 11px;
    line-height: 14px;
}