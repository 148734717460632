@import 'fonts';
@import 'reset';

html,
body {
  position: relative;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  height: 100%;
  font-family: 'ONYOneFont', 'Helvetica', sans-serif;
  font-size: 13px;
  line-height: normal;
  background: #f3f3f3;
}

.content-wrapper {
  min-height: 100%;
}

/*
  В modern layout (app/views/layouts/modern.html.erb) загружаются svg иконки,
  необходимо скрыть контейнер, чтобы иконки не были видны при загрузке страницы

  TODO: удалить вместе с удалением modern.svg из лейаута
*/
.svg-store {
  position: absolute;
  top: -9999px;
  left: -9999px;
  width: 0;
  height: 0;
  opacity: 0;
}
