.searchTypeTabs {
    align-self: flex-end;
    margin-left: 30px;
}

.mobileSearchTypeTabs {
    margin-bottom: 8px;
    margin-left: 8px;
    height: 38px;
}

.menuWrapper {
    display: flex;
    align-items: end;
    height: 39px;
}

.mobileMenuWrapper {
    height: 47px;
}

.menu {
    margin-left: auto;
    z-index: 4;
    height: 38px;
}
