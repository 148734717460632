.container {
    padding: 20px;
}

.heading {
    font-size: 24px;
    margin-top: 20px;
    line-height: 33px;
  
    vertical-align: baseline;
    text-align: center;
}

.aboutHeadingDesktop {
    text-align: left;
}

.aboutContainer {
    display: flex; 
}

.aboutContainerMobile {
    display: block;
}

.aboutInnerContainer {
    width: 580px;
    padding-left: 30px;
}

.description {
    margin: 20px 0;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
}

.middleDescription {
    composes: description;
    line-height: 24px;
    margin: 20px auto;
    color: #4a4a4a;
}

.middleDescriptionDesktop {
    text-align: center;
    max-width: 856px;
}

.delimeter {
    border: 0;
    width: 190px;
    height: 1px;
    background: #cacaca;
    margin: 30px auto;
}

.featureContainer {
    display: flex;
    max-width: 1080px;
    margin: 0 auto;
}

.featureHeading {
    font-size: 18px;
    margin: 15px 0;
    line-height: 26px;
}

.featureHeadingDesktop {
    text-align: center;
}

.feature {
    padding: 10px 20px;
    font-size: 16px;
    line-height: 22px;
    display: flex;
}

.featureDesktop {
    padding: 10px 0 20px 40px;
    width: 510px;
}

.instructionLink {
    color: #4a4a4a;
}

.articlesContainer {
    margin: 10px auto 0;
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.articleLink {
    display: flex;
    vertical-align: middle;
    text-decoration: none;
    margin: 6px 11px; 
}

.articleLinkMobile {
    margin: 20px 0;
}

.articleContent {
    vertical-align: middle;
    font-size: 18px;
    color: #4a4a4a;
    transform: translate(0%, 15%);
    line-height: 30px;
}

.articleContentMobile {
    width: 250px;  
}

.articleImage {
    width: 41px;
    height: 41px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
}

.articleImageMobile {
    width: 45px;
    height: 45px;
    margin: 0 25px 0 20px;
}

.headingIcon {
    width: 33px;
    height: 33px;
    margin-right: 10px;
  
    position: relative;
    vertical-align: top;
    display: inline-block;
}

.headingIcon path {
    fill: #3fa9f5;
}

.featureIcon {
    display: inline-block;
    min-width: 27px;
    height: 27px;
    margin-right: 8px;
}