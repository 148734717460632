.container {
    color: #4d4d4d;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subheading {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;
}