.container {
  display: flex;
  grid-gap: 24px;
  gap: 24px;

  padding: 24px 48px;
  background-color: var(--white);
}

.mobileContainer {
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;

  padding: 12px 8px;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px
}
