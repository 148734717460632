@font-face {
  font-family: 'ONYOneFont';
  src: url('../fonts/ONYOneBeta-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ONYOneFont';
  src: url('../fonts/ONYOneBeta-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
