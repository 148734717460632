.logoWrapper {
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    display: flex;
    height: 56px;
}

.widgetLtLogo {
    height: 40px;
    margin-top: 21px;
    margin-left: 21px;
}

.mobileLtLogo {
  margin: 0 22.15px 0 17px;
  padding: 17px 0;
}

.defaultLtLogo {
  margin: 0 20px 0 20px;
  padding-top: 24px;
}