.contentContainer {
    background: #fff;
    box-sizing: border-box;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.contentContainerMetaregions {
    padding: 8px 2px 12px 8px;
    height: calc(100% - 120px);
}

.contentContainerHotels {
    padding: 8px;
    height: 155px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}