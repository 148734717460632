.container {
  display: flex;
  flex-direction: column;
  flex: 0 0 300px;
  grid-gap: 16px;
  gap: 16px;
}

.mobileContainer {
  grid-gap: 8px;
  gap: 8px;
}

.text {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.mobileText {
  font-size: 12px;
}

.link {
  color: var(--grey1);
}