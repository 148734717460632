.data {
  display: flex;
  align-items: center;
}

.data p:last-child {
  margin-left: 10px;
}

.stars {
  margin-right: 1px !important;
}

.reviews {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #919499;
}

.star path {
  fill: #ff611d;
  stroke: #ff611d;
}
