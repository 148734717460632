.container {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.mobileContainer {
  grid-gap: 4px;
  gap: 4px;
}

.title {
  color: var(--black);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.mobileTitle {
  font-size: 16px;
}

.linksContainer {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.icon {
  height: 56px;
}

.mobileIcon {
  height: 44px;
}
