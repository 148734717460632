.container {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.mobileContainer {
  grid-gap: 4px;
  gap: 4px;
}

.title {
  color: var(--black);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.mobileTitle {
  font-size: 16px;
}

.stores {
  display: flex;
}

.desktopStores {
  justify-content: space-between;
}

.mobileStores {
  grid-gap: 8px;
  gap: 8px;
}
