.containerShadow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    padding-top: 40px;
    padding-bottom: 0px;
    background: linear-gradient(90deg,
    rgba(243, 243, 243, 0) 0%,#f3f3f3 100%
    );
    z-index: 10;
}

.containerShadowRight {
    right: -4px;
}

.containerShadowCountries {
    justify-content: center;
    padding-top: 0px;
}

.containerShadowLeft {
    left: -4px;
    background: linear-gradient(-90deg,
    rgba(243, 243, 243, 0) 0%,#f3f3f3 100%
    );
}

.containerShadowNarrative {
    padding-top: 60px;
}

.containerShadowCountriesWithPrices {
    padding-bottom: 60px;
}

.circleButton {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background: #17181a;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
}

.arrowRightIcon {
    width: 8px;
    height: 18px;
    stroke: #e6e9ee;
}

.arrowLeftIcon {
    composes: arrowRightIcon;
    transform: scale(-1, 1);
}