:root {
  --white: #FFF;
  --black: #000;
  --blue: #007aff;
  --grey1: #73716E;
  --grey2: #B3AEA3;
  --grey3: #E3E1DD;
  --lime: #D2FF1F;
  --lightlime: #EEFFC2;
  --darklime: #70970E;
  --orange: #FF611D;
  --lightorange: #FFE6DD;
  --background: #F3F3F3;

  --hover-alpha: 0.06;
  --pressed-alpha: 0.12;
  --disabled-opacity: 0.4;

  --loader-animation: spin 2s linear infinite;
  --box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(106, 106, 106, 0.08);
  --border-radius: 8px;

  --font-size-extra-large: 22px;
  --font-size-large: 18px;
  --font-size-medium: 16px;
  --font-size-small: 14px;
  --font-size-extra-small: 12px;
  --font-size-mobile-large: 16px;
  --font-size-mobile-medium: 14px;
  --font-size-mobile-small: 12px;
}
