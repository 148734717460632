.logo {
  border: 1px solid #ebecf0;
  border-radius: 15px;
  width: 60px;
  height: 60px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #17181a;
}

.pictureBlock {
  margin-right: 8px;
  min-width: 60px;
}

.buttonText :link {
  color: inherit;
  text-decoration: none;
}

.textBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: auto;
}

.content {
  min-width: 158px;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  float: left;
}

.bannerStars {
  display: flex;
  align-items: flex-start;
  margin-top: 2px;
}

.bannerStars p:last-child {
  margin-left: 3px;
}

.bannerStarsDecimal {
  display: flex;
  align-items: center;
}

.bannerStarsDecimal p:last-child {
  margin-left: 6px;
}

.container {
  display: flex;
  min-width: 370px;
  background-color: #fff;
  height: 76px;
  align-items: center;
}

.seopage {
  position: relative;
  z-index: 1004;
}

.buttonText {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ff611d;
  margin-right: 10px;
  float: right;
}

.wrapper {
  z-index: 1;
  color: rgb(145, 148, 153);
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin: 0 5px;
}
