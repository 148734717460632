.link {
    display: block;
    height: 100%;
}

.citiesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.countryContainer {
    width: 240px;
}

.countryContainerFirst {
    width: 320px;
}

.countryContentWrapper {
    padding: 8px;
    composes: absoluteDivStyles from "../../../../styles.module.css";
}