.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 8px;
}

.dragHandle {
  background-color: #c2c5cc;
  width: 40px;
  height: 2px;
  border-radius: 10px 0px 0px 0px;
  margin-bottom: 14px;
}

.star path {
  fill: #ff611d;
  stroke: #ff611d;
}

.reviews {
  color: #919499;
  margin-left: 4px !important;
}

.description {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: #17181a;
  white-space: pre-line;
  margin-top: 16px;
}

.codeWrapper {
  display: flex;
  padding: 5px 12px 6px 12px;
  grid-gap: 6px;
  gap: 6px;
  border-radius: 6px;
  background: #f5f3f2;
  align-items: center;
  margin: 12px 0;
}

.codeText {
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #ff611d;
}

.descriptionNote {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #919499;
  text-align: center;
}

.goToAppButton {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  width: 100% !important;
  height: 46px;
}

.copyIcon {
  width: 20px;
  height: 20px;
}

.doneIcon {
  width: 20px;
  height: 20px;
}

.doneIcon path {
  stroke: #ff611d;
}

.link {
  width: 100% !important;
  margin: 24px 16px;
}
