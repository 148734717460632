.skeletonContainer {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
    @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
    }
}

.skeletonContainer::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.8) 20%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 0.2)
    );
    animation: shimmer 2s infinite;
    content: '';
}

.skeletonBox {
    width: 300px;
}